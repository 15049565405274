import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { Link } from "gatsby-plugin-intl";
import Map from "../components/Map";
import {
	PageContentWrapper,
	PageContent,
	PageHero,
	PageHeroHeader,
	PageHeroMap,
	PageTwoColWrapper,
	PageBlockWrapper,
	PageBlockTitle,
} from "../assets/styles/pageCommon";
import Places from "../components/Common/Places";
import CounterList from "../components/Common/CounterList";
import { CustomButton } from "../assets/styles/globalCommon";
import { useStaticQuery, graphql } from "gatsby";

const IndexPage = () => {
	const { nodes: continentes } = useStaticQuery(
		graphql`
			query {
				continentes: allInternalContinentes(
					filter: { alternative_id: { ne: null } }
					sort: { fields: title }
				) {
					nodes {
						id: alternative_id
						title
						paises {
							id
							title
							name
							country_code
							centros_total
						}
					}
				}
			}
		`,
	).continentes;
	return (
		<Layout>
			<Seo title="Mapa de estaciones" />
			<section>
				<PageHero>
					<PageHeroHeader>
						<div>
							<Link to="/" className="lineLink">
								<u>Volver a la home</u>
							</Link>
						</div>
					</PageHeroHeader>
					<PageHeroMap>
						<Map lat={42} long={2} zoom={5} id="resortsMap" />
					</PageHeroMap>
				</PageHero>

				{/* <PageContentWrapper>
					<PageContent width="1050px" paddingBottom="0">
						<PageTwoColWrapper>
							<div className="pageCol fullCol">
								<h2>Este es nuestro mapa de cobertura.</h2>
								<p>
									Navega por nuestro mapa para conocer nuestra
									cobertura. Fresh Snow irá incorporando nuevas
									estaciones en los próximos meses, si tu zona aún no
									se encuentra disponible, ten un poco de paciencia que
									estamos trabajando para alcanzar una cobertura global
									muy pronto.
									<br />
									<br />
									¡Y atención! Para el invierno 2021, estaremos
									presentes en todo el hemisferio sur.
								</p>
							</div>
						</PageTwoColWrapper>
					</PageContent>
				</PageContentWrapper> */}

				<PageContentWrapper>
					<PageContent width="1050px" paddingBottom="0">
						<PageTwoColWrapper>
							<div className="pageCol fullCol">
								<h2>Cobertura Fresh Snow en el mundo.</h2>
								<p>
									Nuestro listado de estaciones está en constante
									crecimiento, a medida que vayamos dando de alta
									nuevas zonas, podrás visualizarlas en este mapa y en
									tus búsquedas.
								</p>
								<br />
								<br />
							</div>

							<div className="pageCol fullCol">
								{continentes.map((continente, index) => {
									return (
										<PageBlockWrapper key={index}>
											<PageBlockTitle>
												<h3>{continente.title}</h3>
											</PageBlockTitle>
											<CounterList items={continente.paises} />
										</PageBlockWrapper>
									);
								})}
							</div>

							<div className="pageCol leftCol">
								<p>
									Si tu estación o zona favorita no se encuentra en
									nuestra actual cobertura, puedes sugerirla haciendo
									clic en el siguiente enlace.
								</p>
								<br />
								<CustomButton className="ghost withUnderline">
									<p>Solicitar alta nueva estación</p>
								</CustomButton>
								<br />
								<br />
							</div>
						</PageTwoColWrapper>
					</PageContent>
				</PageContentWrapper>

				<Places />
			</section>
		</Layout>
	);
};

export default IndexPage;
