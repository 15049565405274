import React from "react";
import { CounterListWrapper, CounterListItem } from "./styled";
import { CustomButton } from "../../../assets/styles/globalCommon";
import { Link } from "gatsby";
import Emoji from "../Emoji";
import Tools from "../../../classes/tools";

const CounterList = ({ items }) => {
	if (!items || items.length === 0) return null;

	return (
		<CounterListWrapper>
			{items.map((item, index) => {
				return (
					<CounterListItem key={index}>
						<Link to={`/resortscountry/${item.name}`} />
						{item.country_code && (
							<Emoji
								symbol={Tools.getFlagEmoji(item.country_code)}
								label={item.name}
							/>
						)}

						<h5>{item.title}</h5>
						<CustomButton>
							<p>{item.centros_total}</p>
						</CustomButton>
					</CounterListItem>
				);
			})}
		</CounterListWrapper>
	);
};

export default CounterList;
