import styled from "styled-components";
import { colors, mediaQueryMax } from "../../../assets/styles/vars";
import { CustomButton } from "../../../assets/styles/globalCommon";

export const CounterListWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 25px;
	padding: 25px 0;
	margin-bottom: 25px;
`;

export const CounterListItem = styled.div`
	flex: 0 0 calc(33.3333% - 17px);
	max-width: calc(33.3333% - 17px);
	background-color: ${colors.light20};
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 10px;
	padding: 10px 10px 10px 25px;
	box-sizing: border-box;
	border-radius: 5px;
	overflow: hidden;
	position: relative;
	@media ${mediaQueryMax.lg} {
		flex: 0 0 calc(50% - 25px);
		max-width: calc(50% - 25px);
	}
	@media ${mediaQueryMax.sm} {
		flex: 0 0 100%;
		max-width: 100%;
	}
	a {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
	.icon {
		width: 34px;
	}
	h5 {
		flex: 1;
		margin: 0;
	}
	${CustomButton} {
		min-width: 60px;
	}
`;
