import React from "react";
import { PlacesWrapper, ContentWrapper, Content, PlacesGrid } from "./styles";
import BoxPlaceImg from "../../BoxPlaceImg";
import { Link } from "gatsby-plugin-intl";
import { connect } from "react-redux";

const Places = ({ bootLoaded, lugaresHome }) => {
	return (
		<PlacesWrapper>
			<ContentWrapper>
				<Content>
					<h3>Descubre nuevos lugares</h3>
					<p>
						Sorpréndete con los lugares y estaciones que te quedan por
						conocer, descubre los mejores secretos de la montaña y
						disfruta al máximo tu experiencia en la nieve.
					</p>
				</Content>
				<PlacesGrid>
					{bootLoaded &&
						lugaresHome &&
						lugaresHome.map((lugar, index) => {
							return (
								<div>
									<BoxPlaceImg
										key={index}
										imgSrc={
											lugar.imagen_small && lugar.imagen_small.url
												? lugar.imagen_small.url
												: null
										}
										title={lugar.title}
										description={lugar.subtitle}
										url={lugar.name}
										link={`${lugar.centros.length} estaciones`}
									/>
								</div>
							);
						})}
				</PlacesGrid>
				<Link to="/locations" className="lineLink white">
					<u>Descubre todos los lugares</u>
				</Link>
			</ContentWrapper>
		</PlacesWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		bootLoaded: state.app.boot.success,
		ads:
			state.app.boot.data && state.app.boot.data.ads
				? state.app.boot.data.ads
				: null,
		lugaresHome:
			state.app.boot.data && state.app.boot.data.lugares_home
				? state.app.boot.data.lugares_home
				: null,
	};
};

export default connect(mapStateToProps)(Places);
