import React from "react";
import styled from "styled-components";

const EmojiSpan = styled.span`
	font-family: "NotoColorEmoji";
`;

const Emoji = (props) => (
	<EmojiSpan
		className="emoji"
		role="img"
		aria-label={props.label ? props.label : ""}
		aria-hidden={props.label ? "false" : "true"}
	>
		{props.symbol}
	</EmojiSpan>
);
export default Emoji;
