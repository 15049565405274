import styled from "styled-components";
import { sizes, colors } from "../../../assets/styles/vars";

export const PlacesWrapper = styled.article`
	background: ${colors.black18};
`;

export const ContentWrapper = styled.div`
	max-width: ${sizes.xl2};
	margin: 0 auto 0;
	box-sizing: content-box;
	padding: 50px 25px;
	@media (min-width: ${sizes.md}) {
		padding: 90px 35px;
	}
	@media (min-width: ${sizes.lg}) {
		padding: 50px 35px;
	}
	@media (min-width: ${sizes.lg2}) {
		padding: 90px 50px;
	}
	@media (min-width: ${sizes.lg3}) {
	}
`;

export const Content = styled.div`
	overflow: hidden;
	margin-bottom: 35px;
	@media (min-width: ${sizes.lg}) {
		width: 50%;
	}
	h3 {
		color: ${colors.white};
		margin-bottom: 5px;
	}

	p {
		color: ${colors.white};
		margin-bottom: 35px;
	}
`;

export const PlacesGrid = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin: 0 -10px 20px;
	> div {
		box-sizing: border-box;
		padding: 0 10px;
		flex: 0 0 100%;
		max-width: 100%;
		margin-bottom: 35px;
		@media (min-width: ${sizes.md}) {
			flex: 0 0 33.3333%;
			max-width: 33.3333%;
			margin-bottom: 0;
		}
		a {
			display: block;
		}
	}
`;
